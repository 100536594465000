import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import RemarkCard from './_remarkCard';
import SimpleSKUCard from './_simpleSKUCard';
import MultiSKUCard, { getOptionsFromAttributes } from './_multiSKUCard';
import { Query } from 'react-apollo';
import SearchComboCollection from 'components/SearchComboCollection';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './_gql';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/products', name: translate.products }, { name: this.state.id || translate.new_product }],
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [
      {
        name: translate.product_details,
        fields: [
          {
            label: translate.name + '**',
            type: 'text',
            maxLength: 255,
            name: 'name',
          },
          {
            label: translate.description,
            type: 'html',
            name: 'description',
          },
          {
            label: translate.weight,
            name: 'weight',
            type: 'number',
            render: ({ values, handleChange, isSubmitting }) => (
              <div className="input-group">
                <input
                  name="weight"
                  step={0.1}
                  type="number"
                  className="form-control"
                  value={values.weight}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
                <div className="input-group-append">
                  <span className="input-group-text">kg</span>
                </div>
              </div>
            ),
          },
          {
            label: translate.sort_index,
            name: 'sortIndex',
            type: 'number',
          },
          {
            label: translate.product_collections,
            render: ({ values, setFieldValue, isSubmitting }) => (
              <SearchComboCollection
                disabled={isSubmitting}
                selected={values.collection}
                onChange={collection => setFieldValue('collection', collection)}
              />
            ),
          },
          {
            label: translate.activation,
            type: 'checkbox',
            name: 'active',
            text: translate.yes,
          },
          {
            label: translate.image,
            type: 'images',
            name: 'images',
          },
        ],
      },
      RemarkCard,
      SimpleSKUCard,
      MultiSKUCard,
    ],
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const { images, name, weight, collection, sortIndex, description, active, variations, options, remarkSet } = values;
    const shopId = await localStorage.getItem('shopId');
    const newImages = images.filter(image => typeof image === 'string');
    const imageUploads = images.filter(image => typeof image !== 'string');
    const attributes = options.map(({ name, values }) => ({
      key: name,
      value: JSON.stringify({ values }),
    }));
    const _variations = variations.map(variation => {
      return {
        ...variation,
        price: variation.price - 0,
        quantity: variation.quantity - 0,
        stockItems: (variation.stockItems || []).map(stockItem => ({
          stockItemId: stockItem.stockItemId,
          cost: stockItem.cost - 0,
        })),
      };
    });
    const variables = {
      id,
      input: {
        shopId,
        collectionId: collection && collection.id,
        name,
        weight,
        sortIndex,
        description,
        newImages,
        imageUploads,
        active,
        variations: _variations,
        attributes,
        remarkSet: JSON.stringify(remarkSet),
      },
      warehouseId: localStorage.getItem('stockWarehouseId'),
    };
    const {
      data: { productSet },
    } = await client.mutate({
      mutation: submit,
      variables,
    });
    return productSet.id;
  };

  getInitialData(data) {
    const { node } = data || {},
      {
        name = '',
        description = '',
        collection,
        images = [],
        weight = 0,
        variations = [{}],
        sortIndex = 0,
        updatedAt,
        active = true,
        attributes = [],
        remarkSet = '[]',
      } = node || {};

    const convertedVariations = variations.reduce(
      (prev, { id, attributes = [], quantity, price, sku, stockItems = [] }) => {
        let useQuantity = false;
        try {
          useQuantity = stockItems.length === 1 && stockItems[0].stockItem.productVariantId === id;
        } catch (e) {}
        return [
          ...prev,
          {
            id,
            attributes: attributes.reduce((prev, { key, value }) => {
              return [
                ...prev,
                {
                  key,
                  value,
                },
              ];
            }, []),
            sku,
            quantity: quantity || (stockItems[0] ? stockItems[0].stockItem.quantity : 0),
            stockItems: !!useQuantity
              ? []
              : stockItems.map(({ stockItem, cost }) => ({
                  stockItemId: stockItem.id,
                  name: stockItem.name,
                  cost,
                })),
            price,
          },
        ];
      },
      [],
    );

    let convertedRemarkSet = [{}];
    try {
      convertedRemarkSet = JSON.parse(remarkSet);
    } catch (e) {}

    return {
      name,
      description,
      collection: collection || {},
      images,
      weight,
      variations: convertedVariations,
      sortIndex,
      updatedAt,
      active,
      attributes,
      remarkSet: convertedRemarkSet,
      options: getOptionsFromAttributes(attributes),
    };
  }

  render() {
    const {
      id,
      gql: { prepare },
    } = this.state;
    return (
      <Query
        query={prepare}
        variables={{
          id,
          warehouseId: localStorage.getItem('stockWarehouseId'),
        }}
      >
        {this.renderContent.bind(this)}
      </Query>
    );
  }
}
