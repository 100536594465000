import React from 'react';
import { translate } from 'shared/translate';

export default ({ values: { weights = [] } = {}, setFieldValue, isSubmitting }) => {
  console.log('weights', weights)

  return (
    <div className="card mb-5">
      <div className="card-header">{translate.product_formula_of_weight}</div>
      <div className="card-body p-0">
        <table className="table table-borderless">
          <colgroup>
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="1" />
          </colgroup>
          <thead>
            <tr>
              <th>{translate.max_weight}</th>
              <th>{translate.unit}</th>
              <th>{translate.price_per_unit}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {weights.map((weight, i) => {
              const { id, maxWeight, unit, unitPrice } = weight;
              return (
                <tr key={`${id}-${i}`}>
                  <td className={`align-middle`}>
                    {weights.length - 1 === i ? (
                      <span>{translate.above}</span>
                    ) : (
                      <input
                        type={'number'}
                        className={`form-control form-control-sm`}
                        required={true}
                        value={maxWeight}
                        disabled={isSubmitting}
                        onChange={e => {
                          weight.maxWeight = e.target.value;
                          setFieldValue('weights', weights);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input
                        className={`form-control form-control-sm`}
                        required={true}
                        disabled={isSubmitting}
                        type="number"
                        value={unit}
                        onChange={e => {
                          weight.unit = e.target.value;
                          setFieldValue('weights', weights);
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">kg</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span className="input-group-text">HKD</span>
                      </div>
                      <input
                        className={`form-control form-control-sm`}
                        required={true}
                        disabled={isSubmitting}
                        type="number"
                        value={unitPrice}
                        onChange={e => {
                          weight.unitPrice = e.target.value;
                          setFieldValue('weights', weights);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link text-danger btn-sm"
                      disabled={isSubmitting}
                      onClick={() => {
                        weights.splice(i, 1);
                        setFieldValue('weights', weights);
                      }}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={99}>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    weights.unshift({ id: Math.random() });
                    setFieldValue('weights', weights);
                  }}
                >
                  {translate.new_option}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
